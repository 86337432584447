<template>
  <form-field :name="field.name">
    <!--v-input
      v-if="field.list.length === 1"
      v-bind="field.props"
      v-model="field.value"
      :error="field.error"
      :error-messages="field.errorMessages">{{ field.list[0] }}</v-input-->
    <v-select
      v-model="field.value"
      :items="field.list"
      :menu-props="{ maxHeight:'270' }"
      v-bind="field.props"
      dense
      box
      validate-on-blur
    />
  </form-field>
</template>

<script>
import formField from '@/mixins/form-field'

export default {
  name: 'DealerAppointment',
  mixins: [formField],
}
</script>
